import PropTypes from 'prop-types';

// Utils
import { cn } from 'utils';

// Style
import styles from './CarouselNavButton.module.css';

function CarouselNavButton({
  children, theme, position, onClick, isDisabled, name,
}) {
  return (
    <button
      type="button"
      aria-label={name}
      className={cn([styles.carouselButton, styles[theme], styles[position]])}
      onClick={onClick}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}

CarouselNavButton.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf([
    'accent',
    'dark',
    'light',
  ]),
  position: PropTypes.oneOf(['right', 'left']),
  name: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
};

CarouselNavButton.defaultProps = {
  theme: 'accent',
  position: 'right',
  isDisabled: false,
  onClick: () => {},
};

export default CarouselNavButton;
